// ボトムシート表示切り替え
export const selectorModeValue = ['parking', 'prefecture', 'city'] as const;
export type SelectorMode = typeof selectorModeValue[number];

// 駐車場リストの検索タイプ
export const searchTypeValue = [
  'currentPlace',
  'address',
  'parkingName',
  'selectedAddress',
] as const;
export type SearchType = typeof searchTypeValue[number];
